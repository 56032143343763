var enableDebug = false,
scrolling = false,
windowInitialWidth = 0;


jQuery(function(){


      jQuery('#searchForm').on("submit", function(e) { 
          var s = jQuery( this ).find('input[name="s"]').val(jQuery.trim(jQuery( this ).find('input[name="s"]').val()));
          if (!s.val()) { 
              e.preventDefault(); 
              jQuery( this ).find('input[name="s"]').focus();
          }
      });

      jQuery('#searchForm2').on("submit", function(e) { 
        var s = jQuery( this ).find('input[name="s"]').val(jQuery.trim(jQuery( this ).find('input[name="s"]').val()));
        if (!s.val()) { 
            e.preventDefault(); 
            jQuery( this ).find('input[name="s"]').focus();
        }
    });

      if (jQuery('.site-page-container').hasClass('single-post')){
        checkScroll();
        jQuery(window).on('scroll',function(){
          checkScroll();
        });
        jQuery(window).on('orientationchange, resize',function() {
          checkScroll();
        });
      }
        

      if (jQuery('img.aligncenter').length > 0) {
        jQuery('img.aligncenter').parent('p').css('textAlign','center');
      }



      if (jQuery('.content.pages.smoothScroll').length > 0 ) {

        gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
        
        if (ScrollTrigger.isTouch !== 1) {
            ScrollSmoother.create({
              wrapper: ".wrapper",
              content: ".content",
              smooth: 1.5,
              effects: true,
            });

            if (jQuery('.showFromLeft').length > 0)   
            gsap.utils.toArray(".showFromLeft").forEach(function(elem) {
  
                gsap.fromTo(
                  elem,
                  { opacity: 0, x: -250 },
                  {
                    opacity: 1,
                    x: 0,
                    scrollTrigger: {
                      trigger: elem,
                      scrub: true,
                      start: "-700",
                      end: "-400",
                      //markers: true,
                    },
                  }
                );
            
            });
  
          if (jQuery('.showFromRight').length > 0)  
            gsap.utils.toArray(".showFromRight").forEach(function(elem) {
  
                gsap.fromTo(
                  elem,
                  { opacity: 0, x: 250 },
                  {
                    opacity: 1,
                    x: 0,
                    scrollTrigger: {
                      trigger: elem,
                      scrub: true,
                      start: "-700",
                      end: "-400",
                      // markers: true,
                    },
                  }
                );
            
            });
          }else{
            if (jQuery('.showFromLeft').length > 0)   
              jQuery('.showFromLeft').css('opacity',1);
  
            if (jQuery('.showFromRight').length > 0)  
              jQuery('.showFromRight').css('opacity',1);
            
          }
    
          /* start vertical line animation */

          const countLineDesktopItems = jQuery('[id*="-start"]:not([id*="M-start"])').length;
          const countLineMobileItems = jQuery('[id*="M-start"]').length;

          const animationDesktopLine = () => {
            for(var i=1; i<=countLineDesktopItems; i++){
              if(jQuery('element'+i+'-start') && jQuery('element'+i+'-end')){
                  createScrollAnimation('element'+i+'-start', 'element'+i+'-end', '.sc'+i);
              }
            }
          };

          const animationMobileLine = () => {
            for(var i=1; i<=countLineMobileItems; i++){
              if(jQuery('element'+i+'M-start') && jQuery('element'+i+'M-end'))
                createScrollAnimation('element'+i+'M-start', 'element'+i+'M-end', '.sc'+i+'M');
            }
          };
                  
          if(countLineDesktopItems> 0 || countLineMobileItems > 0){
            

            windowInitialWidth = jQuery(window).width();
            
            if(countLineDesktopItems> 0 && countLineMobileItems == 0){

              animationDesktopLine();

              window.onresize = function () {
                var currentWidth = jQuery(window).width();
                if (currentWidth !== windowInitialWidth) {
                  animation.kill();
                  jQuery('div[id*="-start"] > div[class^="scroll"]').removeAttr("style");
                  animationDesktopLine();    
                  windowInitialWidth = currentWidth;
                }
              };



            }else if(countLineDesktopItems == 0 && countLineMobileItems > 0){

              animationMobileLine();

              window.onresize = function () {
                var currentWidth = jQuery(window).width();
                if (currentWidth !== windowInitialWidth) {
                  animation.kill();
                  $('div[id*="-start"] > div[class^="scroll"]').removeAttr("style");
                  animationMobileLine();    
                  windowInitialWidth = currentWidth;
                }
              };


            }else if(countLineDesktopItems> 0 && countLineMobileItems > 0){

                if (windowInitialWidth < 1024) 
                  animationMobileLine();
                else
                  animationDesktopLine();

                  window.onresize = function () {
                    var currentWidth = jQuery(window).width();

                    if (currentWidth !== windowInitialWidth) {
                      animation.kill();
                      jQuery('div[id*="-start"] > div[class^="scroll"]').removeAttr("style");
                      
                      if (currentWidth < 1024) 
                        animationMobileLine();
                      else
                        animationDesktopLine();

                      windowInitialWidth = currentWidth;
                    }
                  };
                  
            }
          }
          
          
        /* end vertical line animation */
        
    
        
      }


      if (jQuery('.content.pages').length > 0 ) {


        if (jQuery('.showFromBottomFirst').length > 0)    
          gsap.utils.toArray(".showFromBottomFirst").forEach(function(elem) {
    
             gsap.fromTo(
                elem,
                { opacity: 0, 
                  y: 150
                },
                {
                  opacity: 1,
                  y:0,
                  scrollTrigger: {
                    trigger: elem,
                    scrub: true,
                    start: () => "top "+ jQuery(elem).outerHeight()+ "px",
                    //markers: true,
                  },
                }
              );
          
          });

        if (jQuery('.showFromBottom').length > 0)  
          gsap.utils.toArray(".showFromBottom").forEach(function(elem) {


              gsap.fromTo(
                elem,
                { opacity: 0, y: 150 },
                {
                  opacity: 1,
                  y: 0,
                  scrollTrigger: {
                    trigger: elem,
                    scrub: true,
                    start: "-700",
                    end: "-400",
                    // markers: true,
                  },
                }
              );
          
          });
        






      }

      if(jQuery('.content.chi_siamo').length > 0 ) {
        const animationChiSiamo1 = gsap.timeline({ loop: false, paused: true });
        animationChiSiamo1.fromTo(
          "#EllisseTop4",
          { drawSVG: "0%" },
          { duration: 2, drawSVG: "100%" }
        )
          .fromTo(
            "#EllisseTop3",
            { drawSVG: "0%" },
            { duration: 2, drawSVG: "100%" },
            ".5"
          )
          .fromTo(
            "#EllisseTop2",
            { drawSVG: "0%" },
            { duration: 2, drawSVG: "100%" },
            "1"
          )
          .fromTo(
            "#EllisseTop1",
            { drawSVG: "0%" },
            { duration: 2, drawSVG: "100%" },
            "1.5"
          )
          .fromTo(
            "#EllisseBottom4",
            { drawSVG: "0%" },
            { duration: 2, drawSVG: "100%" },
            "2"
          )
          .fromTo(
            "#EllisseBottom3",
            { drawSVG: "0%" },
            { duration: 2, drawSVG: "100%" },
            "2.5"
          )
          .fromTo(
            "#EllisseBottom2",
            { drawSVG: "0%" },
            { duration: 2, drawSVG: "100%" },
            "3"
          )
          .fromTo(
            "#EllisseBottom1",
            { drawSVG: "0%" },
            { duration: 2, drawSVG: "100%" },
            "3.5"
          );

          const animationChiSiamo2 = gsap.timeline({ loop: false, paused: true });
          animationChiSiamo2.fromTo(
            "#esagono",
            { drawSVG: "0%" },
            { duration: 4, drawSVG: "100%" }
          )
            .fromTo(
              "#circle6_1",
              { opacity: "0" },
              { duration: 1, opacity: "1" },
              "3.75"
            )
            .fromTo(
              "#circle6_2",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "4"
            )
            .fromTo(
              "#circle6_3",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "4.25"
            )
  
            .fromTo(
              "#circle5_1",
              { opacity: "0" },
              { duration: 1, opacity: "1" },
              "4.5"
            )
            .fromTo(
              "#circle5_2",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "4.75"
            )
            .fromTo(
              "#circle5_3",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "5"
            )
  
            .fromTo(
              "#circle4_1",
              { opacity: "0" },
              { duration: 1, opacity: "1" },
              "5.25"
            )
            .fromTo(
              "#circle4_2",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "5.5"
            )
            .fromTo(
              "#circle4_3",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "5.75"
            )
  
            .fromTo(
              "#circle3_1",
              { opacity: "0" },
              { duration: 1, opacity: "1" },
              "6"
            )
            .fromTo(
              "#circle3_2",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "6.25"
            )
            .fromTo(
              "#circle3_3",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "6.5"
            )
  
            .fromTo(
              "#circle2_1",
              { opacity: "0" },
              { duration: 1, opacity: "1" },
              "6.75"
            )
            .fromTo(
              "#circle2_2",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "7"
            )
            .fromTo(
              "#circle2_3",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "7.25"
            )
  
            .fromTo(
              "#circle1_1",
              { opacity: "0" },
              { duration: 1, opacity: "1" },
              "7.5"
            )
            .fromTo(
              "#circle1_2",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "7.75"
            )
            .fromTo(
              "#circle1_3",
              { drawSVG: "0%" },
              { duration: 1, drawSVG: "100%" },
              "8"
            );

            ScrollTrigger.create({
              trigger: '.animation-type-1', 
             // markers: true,
              scrub: true,
              onEnter: function() { 
                
                if(!jQuery('.animation-type-1').hasClass("showed")) {
                  animationChiSiamo1.play();
                  jQuery('.animation-type-1').addClass('showed');
                }
              }

            });


            ScrollTrigger.create({
              trigger: '.animation-type-2', 
             // markers: true,
              scrub: true,
              onEnter: function() { 
                
                if(!jQuery('.animation-type-2').hasClass("showed")) {
                  animationChiSiamo2.play();
                  jQuery('.animation-type-2').addClass('showed');
                }
              }

            });
      }

      if(jQuery('.content.come_operiamo').length > 0 ) {
        const animationPartnership = gsap.timeline({ loop: false, paused: true });
        animationPartnership.fromTo('#ellisseObliqua-partnership', {drawSVG:"0%"}, {duration: 1.5, drawSVG:"100%"})
        .fromTo('#dot-partnership9', {opacity:0}, {duration: 0.4, opacity:1}, "0.8")
        .fromTo('#dot-partnership10', {opacity:0}, {duration: 0.4, opacity:1}, "0.9")
        .fromTo('#dot-partnership1', {opacity:0}, {duration: 0.4, opacity:1}, "1")
        .fromTo('#dot-partnership2', {opacity:0}, {duration: 0.4, opacity:1}, "1.1")
        .fromTo('#dot-partnership3', {opacity:0}, {duration: 0.4, opacity:1}, "1.2")
        .fromTo('#dot-partnership4', {opacity:0}, {duration: 0.4, opacity:1}, "1.3")
        .fromTo('#dot-partnership5', {opacity:0}, {duration: 0.4, opacity:1}, "1.4")
        .fromTo('#dot-partnership6', {opacity:0}, {duration: 0.4, opacity:1}, "1.5")
        .fromTo('#dot-partnership7', {opacity:0}, {duration: 0.4, opacity:1}, "1.6")
        .fromTo('#dot-partnership8', {opacity:0}, {duration: 0.4, opacity:1}, "1.7");


        const animationExecution = gsap.timeline({ loop: false, paused: true });
        animationExecution.fromTo(
          "#cubeTop *",
          { drawSVG: "0%" },
          { duration: 2, drawSVG: "100%" }
        )
          .fromTo(
            "#cubeRight *",
            { drawSVG: "0%" },
            { duration: 2, drawSVG: "100%" },
            "1.8"
          )
          .fromTo(
            "#cubeLeft *",
            { drawSVG: "0%" },
            { duration: 2, drawSVG: "100%" },
            "3.8"
          )
          .fromTo(
            "#line",
            { drawSVG: "0%" },
            { duration: 1, drawSVG: "100%" },
            "6"
          )
          .fromTo(
            "#dotTop",
            { opacity: "0" },
            { duration: .5, opacity: "1" },
            "6.5"
          )
          .fromTo(
            "#dotRight",
            { opacity: "0" },
            { duration: .5, opacity: "1" },
            "7"
          )
          .fromTo(
            "#dotLeft",
            { opacity: "0" },
            { duration: .5, opacity: "1" },
            "7.5"
          );

        const animationDelivery = gsap.timeline({ loop: false, paused: true });
        animationDelivery.fromTo('#ellisseInt', {drawSVG:"0%"}, {duration: 1.2, drawSVG:"100%"})
        .fromTo('#ellisseEst', {drawSVG:"0%"}, {duration: 1.2, drawSVG:"100%"}, "0.5") 
        .fromTo('#dot-deliveryCentre', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "0.8")
        .fromTo('#dot-delivery1', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "1.3")			
        .fromTo('#dot-delivery2', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "1.4")
        .fromTo('#dot-delivery3', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "1.5")
        .fromTo('#dot-delivery4', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "1.6")
        .fromTo('#dot-delivery5', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "1.7")
        .fromTo('#dot-delivery6', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "1.8")
        .fromTo('#dot-delivery7', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "1.9")
        .fromTo('#dot-delivery8', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "2")
        .fromTo('#dot-delivery9', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "2.1")
        .fromTo('#dot-delivery10', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "2.2")
        .fromTo('#dot-delivery11', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "2.3")
        .fromTo('#dot-delivery12', {opacity:"0"}, {duration: 0.4, opacity:"1"}, "2.4")			
        .fromTo('#line-delivery1', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "0.8")
        .fromTo('#line-delivery2', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "0.9")
        .fromTo('#line-delivery3', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1")
        .fromTo('#line-delivery4', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1.1")			
        .fromTo('#line-delivery6', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1.2")
        .fromTo('#line-delivery5', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1.3")			
        .fromTo('#line-delivery7', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1.4")
        .fromTo('#line-delivery8', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1.5")
        .fromTo('#line-delivery9', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1.6")
        .fromTo('#line-delivery10', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1.7")
        .fromTo('#line-delivery11', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1.8")
        .fromTo('#line-delivery12', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1.9");

        ScrollTrigger.create({
          trigger: '.animation-partnership', 
         // markers: true,
          scrub: true,
          onEnter: function() { 
            
            if(!jQuery('.animation-partnership').hasClass("showed")) {
              animationPartnership.play();
              jQuery('.animation-partnership').addClass('showed');
            }
          }

        });

        ScrollTrigger.create({
          trigger: '.animation-execution', 
         // markers: true,
          scrub: true,
          onEnter: function() { 
            
            if(!jQuery('.animation-execution').hasClass("showed")) {
              animationExecution.play();
              jQuery('.animation-execution').addClass('showed');
            }
          }

        });

        ScrollTrigger.create({
          trigger: '.animation-delivery', 
         // markers: true,
          scrub: true,
          onEnter: function() { 
            
            if(!jQuery('.animation-delivery').hasClass("showed")) {
              animationDelivery.play();
              jQuery('.animation-delivery').addClass('showed');
            }
          }

        });




      }

      if (jQuery('svg[class*="animation-service-"]').length > 0){

          
          animationService1 = gsap.timeline({ loop: false, paused: true });
          animationService1.fromTo('#cube *', {drawSVG:"0%"}, {duration: 1.5, drawSVG:"100%"})
          .fromTo('#box1 *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "0.5") 
          .fromTo('#box2 *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "1")
          .fromTo('#box3 *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "1.5")
          .fromTo('#logo', {opacity:"0%"}, {duration: 1, opacity:"100%"}, "2");

          animationService2 = gsap.timeline({ loop: false, paused: true });
          animationService2.fromTo('#torre *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"})
          .fromTo('#profilo_esterno *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "0.2") 
          .fromTo('#finestre *', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1");


          animationService3 = gsap.timeline({ loop: false, paused: true });
          animationService3.fromTo('#line-center', {drawSVG:"0%"}, {duration: 1.5, drawSVG:"100%"})
          .fromTo('#line-sx', {drawSVG:"0%"}, {duration: 1.5, drawSVG:"100%"}, "0.5") 
          .fromTo('#line-dx', {drawSVG:"0%"}, {duration: 1.5, drawSVG:"100%"}, "1")
          .fromTo('#industry-center *', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "1.5")
          .fromTo('#industry-sx *', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "2")
          .fromTo('#industry-dx *', {drawSVG:"0%"}, {duration: 1, drawSVG:"100%"}, "2.5");

          animationService4 = gsap.timeline({ loop: false, paused: true });
          animationService4.fromTo('#cube *', {drawSVG:"0%"}, {duration: 1.5, drawSVG:"100%"})
          .fromTo('#box1b *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "0.5") 
          .fromTo('#box2b *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "1")
          .fromTo('#box3b *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "1.5")
          .fromTo('#logob', {opacity:"0%"}, {duration: 1, opacity:"100%"}, "2");
          


          gsap.utils.toArray('[class*="animation-service-"]').forEach(function(elem) {

            ScrollTrigger.create({
              trigger: elem, 
             // markers: true,
              scrub: true,
              onEnter: function() { 
                
                if(!elem.classList.contains("showed")) {

                  if(elem.classList.contains("animation-service-1"))
                      animationService1.play();
                  else if(elem.classList.contains("animation-service-2"))
                      animationService2.play();
                  else if(elem.classList.contains("animation-service-3"))
                      animationService3.play();
                  else if(elem.classList.contains("animation-service-4"))
                      animationService4.play();

                  elem.classList.add('showed');
                }
              }

            });

          });
          

      }

      //partners slider
      if(jQuery('.partnersList').length > 0){
        transformBlocksToSlider();
        jQuery(window).on('orientationchange, resize',function() {
          transformBlocksToSlider();
        });
      }

       //slick slider
       if (jQuery('.slick-slider.secondVersion').length > 0) {

        jQuery('.slick-slider.secondVersion:not(.advantages)').each(function (index) {

                var currentSlider = jQuery(this);
                var setDots = (currentSlider.hasClass('hasDots')) ? true : false;;
                var setArrows = (currentSlider.hasClass('hasArrows')) ? true : false;
                var setAutoplay = (currentSlider.hasClass('autoplay')) ? true : false;
                var fadeIn = (currentSlider.hasClass('fadeIn')) ? true : false;
                var aheight = (currentSlider.hasClass('adaptiveHeight')) ? true : false;

                
                
              
                if(currentSlider.hasClass('multiple')){

                  if(currentSlider.hasClass('col4')){

                    currentSlider.slick({
                      autoplay: setAutoplay,
                      autoplaySpeed: 4000,
                      arrows: setArrows,
                      dots: setDots,
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      adaptiveHeight: aheight,
                      infinite: true,
                      fade: fadeIn,
                      cssEase: 'linear',
                      responsive: [{
                  
                        breakpoint: 1170,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        }
                  
                      },{
                  
                        breakpoint: 970,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                        }
                  
                      },{
                  
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                        }
                  
                      }, {
                  
                        breakpoint: 480,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                        }
                  
                      }]
                  });

                  }else if(currentSlider.hasClass('col3')){

                      currentSlider.slick({
                        autoplay: setAutoplay,
                        autoplaySpeed: 4000,
                        arrows: setArrows,
                        dots: setDots,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        adaptiveHeight: aheight,
                        infinite: true,
                        fade: fadeIn,
                        cssEase: 'linear',
                        responsive: [{
                    
                          breakpoint: 970,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          }
                    
                        },{
                    
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                    
                        }, {
                    
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                    
                        }]
                    });

                  }else{
                      currentSlider.slick({
                        autoplay: setAutoplay,
                        autoplaySpeed: 4000,
                        arrows: setArrows,
                        dots: setDots,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        adaptiveHeight: aheight,
                        infinite: true,
                        fade: fadeIn,
                        cssEase: 'linear',
                        responsive: [{
                    
                          breakpoint: 970,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          }
                    
                        },{
                    
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                    
                        }, {
                    
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                    
                        }]
                    });
                  }
              
  
                }else{
                  currentSlider.slick({
                    autoplay: setAutoplay,
                    autoplaySpeed: 4000,
                    arrows: setArrows,
                    dots: setDots,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    fade: fadeIn,
                    adaptiveHeight: aheight,
                    cssEase: 'linear',
                  });
                }
                   
                

        });
      }


      //slick slider with animations
      if (jQuery('.slick-slider.secondVersion.advantages').length ==1) { 

            
            gsap.fromTo(
              '.slick-slider.secondVersion.advantages',
              { opacity: 0, x: 500 },
              {
                opacity: 1,
                x: 0,
                scrollTrigger: {
                  trigger: '.slick-slider.secondVersion.advantages',
                  scrub: true,
                  start: "-700",
                  end: "-400",
                  onEnter: startSlickSliderAnim,
                  once: true,
                },
              }
            );

            
        


      }

});


function animateFrom(elem, direction) {
  direction = direction || 1;
  var x = 0,
      y = direction * 100;
  if(elem.classList.contains("reveal_fromLeft")) {
    x = -200;
    y = 0;
  } else if (elem.classList.contains("reveal_fromRight")) {
    x = 200;
    y = 0;
  }
  elem.style.transform = "translate(" + x + "px, " + y + "px)";
  elem.style.opacity = "0";
  gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
    duration: 1, 
    x: 0,
    y: 0, 
    autoAlpha: 1, 
    ease: "expo", 
    overwrite: "auto"
  });
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0});
}

function is_touch_device() {
  return 'ontouchstart' in window        // works on most browsers
      || navigator.maxTouchPoints;       // works on IE10/11 and Surface
};



function checkScroll() {
  if( !scrolling ) {
    scrolling = true;
    (!window.requestAnimationFrame) ? setTimeout(updateHeaderStyle, 300) : window.requestAnimationFrame(updateHeaderStyle);
  }
}


function updateHeaderStyle() {

    var singlePostHeaderImg = jQuery('.site-page-container.single-post .entry-header .post-thumbnail').outerHeight(),
    scrollTop = jQuery(window).scrollTop();


    if(scrollTop < singlePostHeaderImg || (scrollTop+jQuery('.site-header').outerHeight()+20) > jQuery('.marqueeFooter').offset().top){ 
      if(!jQuery('.site-header').hasClass('darkMode'))
      jQuery('.site-header').addClass('darkMode');
    }else
        jQuery('.site-header').removeClass('darkMode');
      
     
  scrolling = false;
}


function startSlickSliderAnim(){

  var currentSlider = jQuery('.slick-slider.secondVersion.advantages');
            

  const cyberSecurityAnim = gsap.timeline({loop: false,paused: true,onComplete:function(){ jQuery('svg.cyberSecurity').parent('.slick-slide').removeClass('init'); }}),
  customerCareAnim = gsap.timeline({loop: false,paused: true,onComplete:function(){ jQuery('svg.customerCare').parent('.slick-slide').removeClass('init'); }}),
  businessOpportunityAnim = gsap.timeline({loop: false,paused: true,onComplete:function(){ jQuery('svg.businessOpportunity').parent('.slick-slide').removeClass('init'); }}),
  evolvedCloudAnim = gsap.timeline({loop: false,paused: true,onComplete:function(){ jQuery('svg.evolvedCloud').parent('.slick-slide').removeClass('init'); }}),
  costsReductionAnim = gsap.timeline({loop: false,paused: true,onComplete:function(){ jQuery('svg.costsReduction').parent('.slick-slide').removeClass('init'); }});


  cyberSecurityAnim.fromTo('svg.cyberSecurity #telephone *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "0.5") 
                  .fromTo('svg.cyberSecurity #lucchetto *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "1");

  customerCareAnim.fromTo('svg.customerCare #operatore *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "0.5");

  businessOpportunityAnim.fromTo('svg.businessOpportunity #computer *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "0.5") 
                        .fromTo('svg.businessOpportunity #dollaro *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "0.5");

  evolvedCloudAnim.fromTo('svg.evolvedCloud #nuvola', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "0.5") 
                  .fromTo('svg.evolvedCloud #security *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "0.5");

  costsReductionAnim.fromTo('svg.costsReduction #riduzione-costi *', {drawSVG:"0%"}, {duration: 2, drawSVG:"100%"}, "0.5");


  currentSlider.on('init', function(){

    //console.log("slick initialized");

    currentSlider.find('.slick-slide:not(.slick-cloned)').addClass('init');

    currentSlider.find('.slick-slide:not(.slick-cloned)').each(function (index) {
      if(jQuery(this).hasClass('slick-active')){
          //console.log('run '+jQuery(this).find('svg').attr('class') + 'Anim');
          var animName = eval(jQuery(this).find('svg').attr('class') + 'Anim');
          animName.play();
      }

    });
    
  }); 


  currentSlider.on('afterChange', function(event, slick, currentSlide, nextSlide){
    
    currentSlider.find('.slick-slide').each(function (index) { 
      if(jQuery(this).hasClass('slick-active')){
          
          if(jQuery(this).hasClass('init')){
            //console.log('run '+jQuery(this).find('svg').attr('class') + 'Anim');
            var animName = eval(jQuery(this).find('svg').attr('class') + 'Anim');
            animName.play();

          }
          
      }

    });

  });

  currentSlider.slick({
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: false,
    infinite: true,
    cssEase: 'linear',
    centerMode: false,
    swipeToSlide: true,
    pauseOnHover: true,
    responsive: [{

      breakpoint: 1170,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
      }

    },{

      breakpoint: 970,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
      }

    },{

      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
      }

    }, {

      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
      }

    }]
  });


}


function transformBlocksToSlider(){
  var gridSlider = jQuery('.partnersList');

  var sliderSettings = {
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    fade: false,
    adaptiveHeight: false,
    infinite: true,
    cssEase: 'linear',
      responsive: [
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };


  if(Modernizr.mq('(max-width: 767px)')){
    if(!gridSlider.hasClass('slickInit')){
      gridSlider.addClass('slickInit');
      gridSlider.slick(sliderSettings);
    }
  }else{
    if(gridSlider.hasClass('slickInit')){
      gridSlider.removeClass('slickInit');
      gridSlider.slick("unslick");
    }
  }
  
}