//variables
const hamburgerMenu = document.querySelector(".box-info__menu");
const openHamburgerMenu = document.querySelector(".open-burger-menu");
const closeBtnHamburgerMenu = document.querySelector(".box-head__close");
const body = document.querySelector("body");
const menuLinkHover = document.querySelectorAll(".menu-link-hover");
const sublinkBox = document.querySelectorAll(".sublink-box");
//search
const searchBtn = document.querySelector(".box-info__search");
const searchHeadBtn = document.querySelector(".box-head__search");
const closeSearchMenu = document.querySelector(".close-search");
const searchMenu = document.querySelector(".search-menu");
const searchGo = document.getElementById("searchGo");
// Resize

var initialWidth = 0;
var animationDesktop,animationMobile;

function afiseazaElementul(event) {
  
  const element = event.target;

  if (element.parentElement.classList.contains("menu-item-has-children")) {
    event.preventDefault();
    const ul = document.querySelectorAll("ul");
    ul.forEach((item) => {
      if (item.classList.contains("sub-menu")) {
        if (item.style.display === "flex") {
          item.style.display = "none";
        } else {
          item.style.display = "flex";
        }
      }
    });
  }
}

const menuItem = document.querySelectorAll(".menu-item");
menuItem.forEach((item) => {
  item.addEventListener("click", afiseazaElementul);
});

//searchMenu
searchHeadBtn.onclick = () => {
  searchMenu.style.right = "0";
  searchMenu.style.opacity = "1";
  body.style.overflow = "hidden";
};
searchBtn.onclick = () => {
  searchMenu.style.right = "0";
  searchMenu.style.opacity = "1";

  body.style.overflow = "hidden";
};
closeSearchMenu.onclick = () => {
  searchMenu.style.right = "-100%";
  searchMenu.style.opacity = "0";

  body.style.overflow = "auto";
};

//open/close Menu
hamburgerMenu.onclick = () => {
  openHamburgerMenu.style.right = "0";
  body.style.overflow = "hidden";
};
closeBtnHamburgerMenu.onclick = () => {
  openHamburgerMenu.style.right = "-1147px";
  body.style.overflow = "auto";
};
// videoBox
if (document.querySelector(".video-box")) {
  let player;
  jQuery("[data-fancybox]").fancybox({
    beforeShow: function () {
      player = new Plyr(".video-box #player", {
        muted: false,
        volume: 1,
        autoplay: true,
        controls: ["play", "progress", "volume", "fullscreen"],
        ads: { enabled: false },
      });
      window.player = player;
    },
    beforeClose: function () {
      player.destroy();
    },
  });
}

//open/close Links
menuLinkHover.forEach((menuLink, indexMenuLink) => {
  sublinkBox.forEach((sublinkBox, indexSublinkBox) => {
    if (
      indexMenuLink === indexSublinkBox &&
      !menuLink.classList.contains("a_block")
    ) {
      menuLink.addEventListener("click", () => {
        sublinkBox.classList.toggle("none");
      });
    }
  });
});

// swipe left anim
if (document.querySelector('[class*="__swipe"] .icon-arrow-left')) {
  gsap.fromTo(
    '[class*="__swipe"] .icon-arrow-left',
    { opacity: 0, x: 50 },
    {
      opacity: 1,
      x: -20,
      duration: 2,
      repeat: -1,
    }
  );
}

const calculateDistance = (div1, div2) => {
  const rect1 = div1.getBoundingClientRect();
  const rect2 = div2.getBoundingClientRect();

  const center1X = rect1.left + rect1.width / 2;
  const center1Y = rect1.top + rect1.height / 2;

  const center2X = rect2.left + rect2.width / 2;
  const center2Y = rect2.top + rect2.height / 2;

  const distance = Math.sqrt(
    (center2X - center1X) ** 2 + (center2Y - center1Y) ** 2
  );

  return distance;
};

const getVisiblePageDimensions = () => {
  var pageWidth =
  jQuery(window).width() ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  var pageHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return { width: pageWidth, height: pageHeight };
};

const createScrollAnimation = (startElementId, endElementId, scrollClass) => {
  const startElement = document.getElementById(startElementId);
  const finishElement = document.getElementById(endElementId);
  const finishElementTop = finishElement.getBoundingClientRect().top;

  const distance = calculateDistance(startElement, finishElement);

  const line = startElement.children[0];

  let lineVelocity = 500;
  if(line.classList.contains("short-line"))
      lineVelocity= 200;

  
  if (
    (line.classList.contains("scroll") &&
      !line.classList.contains("scroll-init")) ||
    (line.classList.contains("scrollM") &&
      !line.classList.contains("scroll-init"))
  ) {
    animation = gsap.fromTo(
      line,
      { height: 0 },
      {
        height: distance,
        scrollTrigger: {
          trigger: line,
          start: "-"+lineVelocity, 
          end: finishElementTop - lineVelocity, 
          scrub: true,
          //onEnter:function(){console.log('start line '+line.classList);},
          //onLeave:function(){console.log('end line '+line.classList);},
          //markers: true,
        },
      }
    );
  }
  if (line.classList.contains("scroll-init")) {
    const line1_start = document.getElementById(startElementId);
    const line1_finish = document.getElementById(endElementId);
    const line1Height = calculateDistance(line1_start, line1_finish);

    let h = 0;
    let initScroll = () => {
      if (h <= line1Height) {
        line.style.height = `${h}px`;
        h += 10;
        setTimeout(initScroll, 20);
        h = h;
      }
    };

    setTimeout(initScroll, 20);

  }
};

// -----------------------------------------------------------

// Verify HOME Page
if (document.querySelector(".homePage")) {
  // SLICK SLIDER
  jQuery(".slider-elements").slick({
    infinite: false,
    dots: false,
    arrows: false,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  });

  jQuery(".slider-partners-logoM").slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          infinite: true,
          autoplay: true,
          dots: false,
          arrows: false,
          slidesToShow: 1.65,
          slidesToScroll: 1,
        },
      },
    ],
  });


  //get current data
  function getCurrentDateAndTime() {
    const dateTime = new Date();
    return dateTime.toLocaleDateString();
  }
  const dateDisplay = document.querySelectorAll(".current-date");
  dateDisplay.forEach((item) => {
    item.innerHTML = getCurrentDateAndTime();
  });

  // GSAP
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  //

  // click move section
  const scrollButton = document.querySelector(".scroll-down-img");
  const sectionCases = document.getElementById("section_cases");

  scrollButton.addEventListener("click", () => {
    gsap.to(window, {
      duration: 3,
      scrollTo: sectionCases,
    });
  });
  if (ScrollTrigger.isTouch !== 1) {
    ScrollSmoother.create({
      wrapper: ".wrapper",
      content: ".content",
      smooth: 1.5,
      effects: true,
    });

    /*particlesJS.load(
      "particles-js",
      "js/particle/particles.json",
      function () {}
    );*/
    

    (function () {
      let chck_if_gsap_loaded = setInterval(function () {
        const eleBuilder = document
          .querySelector("body")
          .classList.contains("elementor-editor-active");
        if (window.gsap && window.ScrollTrigger && !eleBuilder) {
          gsap.registerPlugin(ScrollTrigger);
          animateImages(cols);
          clearInterval(chck_if_gsap_loaded);
        }
      }, 100);
      const cols = gsap.utils.toArray(".col");
      function animateImages(cols) {
        let offset = 0;

        cols.forEach((col, i) => {
          const images = col.childNodes;

          // DUPLICATE IMAGES FOR LOOP
          /*images.forEach((image) => {
            var clone = image.cloneNode(true);
            col.appendChild(clone);
          });*/

          // SET ANIMATION
          images.forEach((item) => {
            let columnHeight = item.parentElement.offsetHeight;
            let direction = i % 2 !== 0 ? "+=" : "-="; // Change direction for odd columns
            let yOffset = direction === "+=" ? -offset : offset;

            let tween = gsap.to(item, {
              y: direction + Number(columnHeight / 2),
              duration: 40,
              repeat: -1,
              ease: "none",
              onRepeat: () => {
                offset = 0;
              },
              modifiers: {
                y: gsap.utils.unitize((y) => {
                  y = (parseFloat(y) + yOffset) % (columnHeight * 0.5);
                  return y;
                }),
              },
            });
            document.querySelectorAll(".col").forEach((img) => {
              img.addEventListener("mouseover", () => {
                if (tween) tween.pause();
              });

              img.addEventListener("mouseout", () => {
                if (tween) tween.resume();
              });
            });
          });
        });
      }
    })();

    gsap.fromTo(
      ".slider-parent",
      { opacity: 0, x: 500 },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".slider-parent",
          start: "-600",
          end: "-200",
          scrub: true,
          // markers: true,
        },
      }
    );

    gsap.fromTo(
      ".slider-elements ",
      { opacity: 1 },
      {
        opacity: 0,

        scrollTrigger: {
          trigger: ".slider-elements ",
          start: `top`,
          end: `bottom`,
          scrub: true,
          // markers: true,
        },
      }
    );

    let itemsSlider = gsap.utils.toArray(
      ".partners__box-left .partners__box-item"
    );
    itemsSlider.forEach((item) => {
      gsap.fromTo(
        item,
        { opacity: 0, x: -250 },
        {
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: item,
            scrub: true,
            start: "-700",
            end: "-400",
            // markers: true,
          },
        }
      );
    });

    let itemsNewsL = gsap.utils.toArray(
      ".news__box-left .news__box-left__element"
    );
    itemsNewsL.forEach((item) => {
      gsap.fromTo(
        item,
        { opacity: 0, x: -150 },
        {
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: item,
            scrub: true,
            start: "-700",
            end: "-300",
            // markers: true,
          },
        }
      );
    });

    let itemsNewsR = gsap.utils.toArray(
      ".news__box-right .news__box-right__element"
    );
    itemsNewsR.forEach((item) => {
      gsap.fromTo(
        item,
        { opacity: 0, x: 150 },
        {
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: item,
            scrub: true,
            start: "-700",
            end: "-300",
            // markers: true,
          },
        }
      );
    });

    gsap.fromTo(
      ".partners .section-text .section-text__left",
      { opacity: 0, x: -250 },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".partners  .section-text .section-text__left",
          scrub: true,
          start: "-700",
          end: "-400",
          // markers: true,
        },
      }
    );
    gsap.fromTo(
      ".partners .section-text .section-text__right",
      { opacity: 0, x: 250 },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".partners  .section-text .section-text__right",
          scrub: true,
          start: "-700",
          end: "-400",
          // markers: true,
        },
      }
    );

    //vertical line------------------------------------------------------------------------------------------------------------------

    const scroll1 = document.querySelector(".sc1");

    const el1_finish = document.getElementById("lottie");

    const el1_finish_top = el1_finish.getBoundingClientRect().top;

    let h = 0;
    let initScroll = () => {
      if (h <= el1_finish_top) {
        scroll1.style.height = `${h}px`;
        h += 3;
        setTimeout(initScroll, 8);
        h = h;
      }
    };

    setTimeout(initScroll, 20);
    const scroll1_1 = document.querySelector(".sc1Bt");

    const el1_start = document.getElementById("element1Bt-start");
    const el11_finish = document.getElementById("element1-end");
    setTimeout(() => {
      const el11_finish_top = el11_finish.getBoundingClientRect().top;

      const distanceEl1 = calculateDistance(el1_start, el11_finish);

      gsap.fromTo(
        scroll1_1,
        { height: 0 },
        {
          height: distanceEl1,

          scrollTrigger: {
            trigger: scroll1_1,
            start: "-200",
            end: el11_finish_top,
            scrub: true,
           // markers: true,
          },
        }
      );
    }, 1500);

    const el2_start = document.getElementById("element2-start");
    const el2_finish = document.getElementById("element2-end");
    setTimeout(() => {
      const el2_finish_top = el2_finish.getBoundingClientRect().top;

      const distanceEl2 = calculateDistance(el2_start, el2_finish);

      gsap.fromTo(
        ".sc2",
        { height: 0 },
        {
          height: distanceEl2,
          scrollTrigger: {
            trigger: ".sc2",
            start: "-200",
            end: el2_finish_top,
            scrub: true,
           //markers: true,
          },
        }
      );
    }, 1500);

    const el3_start = document.getElementById("element3-start");
    const el3_finish = document.getElementById("element3-end");
    // setTimeout(() => {
    const el3_finish_top = el3_finish.getBoundingClientRect().top;

    const distanceEl3 = calculateDistance(el3_start, el3_finish);

    gsap.fromTo(
      ".sc3",
      { height: 0 },
      {
        height: distanceEl3,
        scrollTrigger: {
          trigger: ".sc3",
          start: "-200",
          end: el3_finish_top * 1.1,
          scrub: true,
          // markers: true,
        },
      }
    );
    // }, 1500);

    // ----------------------------------------------------------------------
  }
  // Line MOBILE
  const scroll1 = document.querySelector(".sc1M");
  var visibleDimensions = getVisiblePageDimensions();
  var hPage = visibleDimensions.height;

  const el1M_finish = document.getElementById("lottie");
  const el1M_finish_top = el1M_finish.getBoundingClientRect().top;

  let h = 0;
  let initScroll = () => {
    if (h <= el1M_finish_top) {
      scroll1.style.height = `${h}px`;
      h += 0.5;
      setTimeout(initScroll, 8);
      h = h;
    }
  };

  setTimeout(initScroll, 100);

  const scroll1_1 = document.querySelector(".sc1_1M");
  const el1_1M_finish = document.querySelector(".entry-title");

  const el1_1M_finish_top = el1_1M_finish.getBoundingClientRect().top;

  let h1 = 0;
  let initScroll1 = () => {
    if (h1 <= 50) {
      scroll1_1.style.height = `${h1}px`;
      h1 += 0.5;
      setTimeout(initScroll1, 8);
      h1 = h1;
    }
  };
  setTimeout(() => {
    setTimeout(initScroll1, 100);
  }, 8000);

  const el2M_start = document.getElementById("element2M-start");
  const el2M_finish = document.getElementById("element2M-end");
  const el2M_finish_top = el2M_finish.getBoundingClientRect().top;

  let distanceEl2M = calculateDistance(el2M_start, el2M_finish);

  gsap.fromTo(
    ".sc2M",
    { height: 0 },
    {
      height: distanceEl2M,
      scrollTrigger: {
        trigger: ".sc2M",
        start: "-200",
        end: el2M_finish_top,
        scrub: true,
        // markers: true,
      },
    }
  );
  const el3M_start = document.getElementById("element3M-start");
  const el3M_finish = document.getElementById("element3M-end");
  const el3M_finish_top = el3M_finish.getBoundingClientRect().top;

  let distanceEl3M = calculateDistance(el3M_start, el3M_finish);

  gsap.fromTo(
    ".sc3M",
    { height: 0 },
    {
      height: distanceEl3M,
      scrollTrigger: {
        trigger: ".sc3M",
        start: "-200",
        end: el3M_finish_top,
        scrub: true,
        // markers: true,
      },
    }
  );
  const el4M_start = document.getElementById("element4M-start");
  const el4M_finish = document.getElementById("element4M-end");
  const el4M_finish_top = el4M_finish.getBoundingClientRect().top;

  let distanceEl4M = calculateDistance(el4M_start, el4M_finish);

  gsap.fromTo(
    ".sc4M",
    { height: 0 },
    {
      height: distanceEl4M,
      scrollTrigger: {
        trigger: ".sc4M",
        start: "-200",
        end: el4M_finish_top,
        scrub: true,
        // markers: true,
      },
    }
  );
  const el5M_start = document.getElementById("element5M-start");
  const el5M_finish = document.getElementById("element5M-end");
  const el5M_finish_top = el5M_finish.getBoundingClientRect().top;

  let distanceEl5M = calculateDistance(el5M_start, el5M_finish);

  gsap.fromTo(
    ".sc5M",
    { height: 0 },
    {
      height: distanceEl5M,
      scrollTrigger: {
        trigger: ".sc5M",
        start: "-200",
        end: el5M_finish_top,
        scrub: true,
        // markers: true,
      },
    }
  );
  const el6M_start = document.getElementById("element6M-start");
  const el6M_finish = document.getElementById("element6M-end");
  const el6M_finish_top = el6M_finish.getBoundingClientRect().top;

  let distanceEl6M = calculateDistance(el6M_start, el6M_finish);

  gsap.fromTo(
    ".sc6M",
    { height: 0 },
    {
      height: distanceEl6M,
      scrollTrigger: {
        trigger: ".sc6M",
        start: "-200",
        end: el6M_finish_top,
        scrub: true,
        // markers: true,
      },
    }
  );
}
// verify Page
if (document.querySelector(".chi_siamo")) {

  initialWidth = jQuery(window).width();

  // sliders
  jQuery(".mission-box__value").slick({
    infinite: true,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    variableWidth: true,
    swipeToSlide: true,
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 1000,
    pauseOnHover: true,
  });
  jQuery(".slider-elements").slick({
    infinite: false,
    dots: false,
    arrows: false,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  });

  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  if (ScrollTrigger.isTouch !== 1) {
    ScrollSmoother.create({
      wrapper: ".wrapper",
      content: ".content",
      smooth: 1.5,
      effects: true,
    });

    
    gsap.fromTo(
      ".info-right-block__right",
      { opacity: 0, x: 500 },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".info-right-block__right",
          start: "-600",
          end: "-300",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      ".video-poster",
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: ".video-poster",
          start: "-700",
          end: "-400",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      ".video-box",
      { opacity: 1 },
      {
        opacity: 0,
        scrollTrigger: {
          trigger: ".video-box",
          start: "center",
          end: "bottom",
          scrub: true,
        },
      }
    );
    const sliders = [".slider-box", ".mission-box__value"];

    sliders.forEach((item) => {
      gsap.fromTo(
        item,
        { opacity: 0, x: 500 },
        {
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: item,
            scrub: true,
            start: "-700",
            end: "-400",
            // markers: true,
          },
        }
      );
    });
  }

  /* start vertical line animation */

  const countLineDesktopItems = jQuery('[id*="-start"]:not([id*="M-start"])').length;
  const countLineMobileItems = jQuery('[id*="M-start"]').length;

  const animationDesktopLine = () => {
    for(var i=1; i<=countLineDesktopItems; i++){
      if(jQuery('element'+i+'-start') && jQuery('element'+i+'-end')){
          createScrollAnimation('element'+i+'-start', 'element'+i+'-end', '.sc'+i);
      }
    }
  };

  const animationMobileLine = () => {
    for(var i=1; i<=countLineMobileItems; i++){
      if(jQuery('element'+i+'M-start') && jQuery('element'+i+'M-end'))
        createScrollAnimation('element'+i+'M-start', 'element'+i+'M-end', '.sc'+i+'M');
    }
  };
          
  if(countLineDesktopItems> 0 || countLineMobileItems > 0){
    

    windowInitialWidth = jQuery(window).width();
    
    if(countLineDesktopItems> 0 && countLineMobileItems == 0){

      animationDesktopLine();

      window.onresize = function () {
        var currentWidth = jQuery(window).width();
        if (currentWidth !== windowInitialWidth) {
          animation.kill();
          jQuery('div[id*="-start"] > div[class^="scroll"]').removeAttr("style");
          animationDesktopLine();    
          windowInitialWidth = currentWidth;
        }
      };



    }else if(countLineDesktopItems == 0 && countLineMobileItems > 0){

      animationMobileLine();

      window.onresize = function () {
        var currentWidth = jQuery(window).width();
        if (currentWidth !== windowInitialWidth) {
          animation.kill();
          jQuery('div[id*="-start"] > div[class^="scroll"]').removeAttr("style");
          animationMobileLine();    
          windowInitialWidth = currentWidth;
        }
      };


    }else if(countLineDesktopItems> 0 && countLineMobileItems > 0){

        if (windowInitialWidth < 1024) 
          animationMobileLine();
        else
          animationDesktopLine();

          window.onresize = function () {
            var currentWidth = jQuery(window).width();

            if (currentWidth !== windowInitialWidth) {
              animation.kill();
              jQuery('div[id*="-start"] > div[class^="scroll"]').removeAttr("style");
              
              if (currentWidth < 1024) 
                animationMobileLine();
              else
                animationDesktopLine();

              windowInitialWidth = currentWidth;
            }
          };
          
    }
  }
  
  
/* end vertical line animation */
}
if (document.querySelector(".come_operiamo")) {
  initialWidth = jQuery(window).width();

  
  jQuery(".slider__box").slick({
    infinite: false,
    dots: false,
    arrows: false,
    variableWidth: true,
    slidesToScroll: 1,
    centerMode: true,
    // centerPadding: "30%",
    swipeToSlide: true,
  });

  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
  if (ScrollTrigger.isTouch !== 1) {
    ScrollSmoother.create({
      wrapper: ".wrapper",
      content: ".content",
      smooth: 1.5,
      effects: true,
    });

    gsap.fromTo(
      ".info-box",
      { opacity: 1 },
      {
        opacity: 0,
        scrollTrigger: {
          trigger: ".info-box",
          start: "top",
          end: "center",
          scrub: true,
        },
      }
    );

    const sliders = [".partnership", ".execution", ".delivery"];

    sliders.forEach((item) => {
      gsap.fromTo(
        item,
        { opacity: 0, x: 100 },
        {
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: item,
            scrub: true,
            start: "-700",
            end: "-400",
            // markers: true,
          },
        }
      );
    });

    let itemsFutureL = [
      ".future-box__info-box__left__h",
      " .future-box__info-box__left__p",
    ];

    itemsFutureL.forEach((item) => {
      gsap.fromTo(
        item,
        { opacity: 0, x: -150 },
        {
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: item,
            scrub: true,
            start: "-700",
            end: "-300",
            // markers: true,
          },
        }
      );
    });
    gsap.fromTo(
      ".future-box__info-box__right__p",
      { opacity: 0, x: 150 },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: ".future-box__info-box__right__p",
          scrub: true,
          start: "-700",
          end: "-300",
          // markers: true,
        },
      }
    );
  }
  
  /* start vertical line animation */

  const countLineDesktopItems = jQuery('[id*="-start"]:not([id*="M-start"])').length;
  const countLineMobileItems = jQuery('[id*="M-start"]').length;
          
  if(countLineDesktopItems> 0 || countLineMobileItems > 0){
    
    if(countLineDesktopItems> 0 && countLineMobileItems == 0){

      const animationDesktopLine = () => {
        for(var i=1; i<=countLineDesktopItems; i++){
          if(jQuery('element'+i+'-start') && jQuery('element'+i+'-end'))
            createScrollAnimation('element'+i+'-start', 'element'+i+'-end', '.sc'+i);
        }
      };

      animationDesktopLine();

      window.onresize = function () {
        var currentWidth = jQuery(window).width();

        if (currentWidth !== initialWidth) {
          animation.kill();
          jQuery('div[id*="-start"] > div[class^="scroll"]').removeAttr("style");
          animationDesktopLine();    
          initialWidth = currentWidth;
        }
      };



    }else if(countLineDesktopItems == 0 && countLineMobileItems > 0){

      const animationMobileLine = () => {
        for(var i=1; i<=countLineMobileItems; i++){
          if(jQuery('element'+i+'M-start') && jQuery('element'+i+'M-end'))
            createScrollAnimation('element'+i+'M-start', 'element'+i+'M-end', '.sc'+i+'M');
        }
      };

      animationMobileLine();

      window.onresize = function () {
        var currentWidth = jQuery(window).width();

        if (currentWidth !== initialWidth) {
          animation.kill();
          jQuery('div[id*="-start"] > div[class^="scroll"]').removeAttr("style");
          animationMobileLine();    
          initialWidth = currentWidth;
        }
      };


    }else if(countLineDesktopItems> 0 && countLineMobileItems > 0){
        const animationDesktopLine = () => {
          for(var i=1; i<=countLineDesktopItems; i++){
            if(jQuery('element'+i+'-start') && jQuery('element'+i+'-end'))
              createScrollAnimation('element'+i+'-start', 'element'+i+'-end', '.sc'+i);
          }
        };

        const animationMobileLine = () => {
          for(var i=1; i<=countLineMobileItems; i++){
            if(jQuery('element'+i+'M-start') && jQuery('element'+i+'M-end'))
              createScrollAnimation('element'+i+'M-start', 'element'+i+'M-end', '.sc'+i+'M');
          }
        };

        if (initialWidth < 1024) 
          animationMobileLine();
        else
          animationDesktopLine();

          window.onresize = function () {
            var currentWidth = jQuery(window).width();

            if (currentWidth !== initialWidth) {
              animation.kill();
              jQuery('div[id*="-start"] > div[class^="scroll"]').removeAttr("style");
              
              if (currentWidth < 1024) 
                animationMobileLine();
              else
                animationDesktopLine();

              initialWidth = currentWidth;
            }
          };
          
    }
  }
  
  
 /* end vertical line animation */
}
setTimeout(() => {
  ScrollTrigger.refresh();
}, 500);
